<template>
  <div class="chat-box">
    <div class="tab-header">
      <el-tabs v-model="activeName"
                @tab-click="tabsClick">
        <el-tab-pane label="直播间" name="first"></el-tab-pane>
        <el-tab-pane
          :label="'聊天室' + count + '人'"
          name="second"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="activeName == 'first'">
      <virtual-list
        class="msg-box"
        ref="msg-box"
        data-key="id"
        :data-sources="list"
        :data-component="ListComponent"
      >
      </virtual-list>
    </div>
    <div v-if="activeName == 'second'">
      <virtual-list
        class="msg-box"
        ref="msg-box"
        data-key="id"
        :data-sources="list1"
        :data-component="ListComponent"
        @totop="onTotop"
      >
        <div slot="header" class="header">
          <div class="spinner" v-show="!finished"></div>
          <div class="finished" v-show="finished">没有更多了</div>
        </div>
      </virtual-list>
    </div>
    <div class="input-box clear">
      <div class="input-at fl" v-if="activeName == 'first'">
        <input
          type="text"
          ref="sendMsg"
          v-model="contentText"
          @keyup.enter="sendText()"
          maxlength="150"
          :placeholder="placeholder"
          :disabled="disabled"
        />
      </div>
      <div class="input-at fl" v-else>
        <input
          type="text"
          ref="sendMsg"
          v-model="contentText"
          @keyup.enter="sendText()"
          maxlength="150"
          :placeholder="placeholders"
          :disabled="disableds"
        />
      </div>
      <div :class="['btns', active ? 'active' : '']" @click="sendText()"></div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import { mapGetters } from 'vuex';
import { pupopStatus } from '@/config/pupupUtils';
import { getChatCacheRecord, getChatDbRecord } from '@/api/api';
import List from './list';
import VirtualList from 'vue-virtual-scroll-list';
var timer;
export default {
  name: 'Chat',
  components: {
    'virtual-list': VirtualList
  },
  props: {
    activityCourseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ws: null,
      finished: false,
      count: 0,
      activeName: 'first',
      active: false,
      placeholder: '当前为禁言状态',
      placeholders: '请输入聊天内容',
      disabled: true,
      disableds: false,
      list: [], // 主持人发送的信息
      list1: [], // 聊天室信息
      contentText: '', // input输入的值
      isRoom: false,
      isuserIsOpen: true,
      ListComponent: List
    };
  },
  watch: {
    contentText(vol) {
      if (vol != '') {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    activityCourseId(val) {
      this.loadChatCacheRecord();
      this.initWebSocket();
    }
  },
  computed: {
    ...mapGetters(['token', 'userId'])
  },
  methods: {
    loadChatCacheRecord() {
      getChatCacheRecord(this.activityCourseId).then((res) => {
        this.list1 = res.data;
        if (res.data.length <= 49) {
          this.loadChatDbRecord();
        }
      });
    },
    tabsClick(val) {
      this.$nextTick(() => {
        this.$refs['msg-box'].scrollToBottom();
      });
    },
    onTotop() {
      if (this.isFetching) return;
      this.isFetching = true;
      const params = { roomId: this.activityCourseId };
      if (this.list1.length > 0) {
        params.msgId = this.list1[0].id;
      }
      getChatDbRecord(params).then((res) => {
        if (res.data.size < 10) {
          this.finished = true;
          return;
        }
        const msgs = res.data.list.reverse();
        const ids = msgs.map((msg) => msg.id);
        this.list1 = msgs.concat(this.list1);
        this.$nextTick(() => {
          const offset = ids.reduce((previousValue, currentId, currentIndex) => {
            const previousSize = currentIndex === 1
              ? this.$refs['msg-box'].getSize(previousValue)
              : previousValue;
            return previousSize + this.$refs['msg-box'].getSize(currentId);
          });
          this.setVirtualListToOffset(offset);
          this.isFetching = false;
        });
      });
    },
    setVirtualListToOffset(offset) {
      if (this.$refs['msg-box']) {
        this.$refs['msg-box'].scrollToOffset(offset);
      }
    },
    loadChatDbRecord() {
      const params = { roomId: this.activityCourseId };
      if (this.list1.length > 0) {
        params.msgId = this.list1[0].id;
      }
      getChatDbRecord(params).then((res) => {
        this.list1 = [...res.data.list.reverse(), ...this.list1];
      });
    },
    // 发送聊天信息
    sendText() {
      const _this = this;
      _this.$refs['sendMsg'].focus();
      if (!_this.contentText) return;
      const actions = {
        type: 'msg',
        text: _this.contentText
      };
      _this.websock.send(JSON.stringify(actions));
      _this.contentText = '';
    },
    // 进入页面连接
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket');
        return;
      }
      // const wsuri = `wss://mns.south-edu.cn/?Authorization=hys${this.token()}`;
      // const wsuri = `wss://mns.dev.gba-edu.cn/?Authorization=hys${this.token()}`;
      const wsuri = `${
        process.env.VUE_APP_BASE_URL
      }?Authorization=hys${this.token()}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    // 连接建立之后执行send方法发送数据
    websocketonopen() {
      const actions = {
        type: 'subject',
        roomId: parseInt(this.activityCourseId),
        text: '这个是条测试数据'
      };
      this.websock.send(JSON.stringify(actions));
      timer = setInterval(() => {
        this.websock.send('ping');
      }, 5000);
    },
    // 失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    // 数据接收
    websocketonmessage(e) {
      const data = JSON.parse(e.data);
      if (data.type == 'roomIsOpen') {
        // 全局禁言
        if (data.msg == 'true') {
          this.isRoom = false;
          if (this.isuserIsOpen) {
            this.disableds = false;
            this.placeholders = '请输入聊天内容';
          }
        } else {
          this.disableds = true;
          this.placeholders = '当前为禁言状态';
          this.contentText = '';
          this.isRoom = true;
        }
        return;
      }
      if (data.type == 'userIsOpen') {
        // 检测是否被禁言
        if (data.msg == 'true' && !this.isRoom) {
          this.disableds = false;
          this.placeholders = '请输入聊天内容';
          return;
        } else {
          this.disableds = true;
          this.placeholders = '当前为禁言状态';
          this.contentText = '';
          const obj = {
            title: pupopStatus(6)[0],
            headline: '系统提示'
          };
          this.$periodPopup(obj).then(() => {});
        }
      }
      if (data.type != 'roomIsOpen' || data.type != 'userIsOpen') {
        switch (data.type) {
          case 'peopleNum':
            this.count = data.msg;
            break;
          case 'userMsg': // 发送的信息返回
            const obj = data.chatUserDto;
            console.log(obj);
            if (data.chatUserDto.isAdminNotice) {
              // 主持人说的话
              this.list.push(obj);
            } else {
              this.list1.push(obj);
            }
            console.log(this.list1);
            setTimeout(() => {
              this.$refs['msg-box'].scrollToBottom();
            }, 20);
            break;
          case 'error':
            Utils.msgWarning(data.msg);
            break;
          case 'banedUser': // 用户被禁言
            const { tempUserInfoDto } = data;
            if (tempUserInfoDto.userId == this.userId) {
              this.isuserIsOpen = false;
              if (!this.isRoom) {
                this.disableds = true;
                this.placeholders = '当前为禁言状态';
                this.contentText = '';
              }
              const obj = {
                title: pupopStatus(6)[0],
                headline: '系统提示'
              };
              this.$periodPopup(obj).then(() => {});
            }
            break;
          case 'freedUser': // 用户解除禁言
            if (data.tempUserInfoDto.userId == this.userId) {
              this.isuserIsOpen = true;
              if (!this.isRoom) {
                this.disableds = false;
                this.placeholders = '请输入聊天内容';
              } else {
                this.disableds = true;
                this.placeholders = '当前为禁言状态';
              }
            }
            break;
          case 'removeRoom': // 改变直播时监听
            this.disableds = true;
            this.placeholders = '当前为禁言状态';
            this.contentText = '';
            this.$emit('broadcastIncident', { code: 200, msg: '直播结束了' });
            break;
          default:
            break;
        }
      }
    },
    // 数据发送
    websocketsend(Data) {
      this.websock.send(Data);
    },
    // 断开连接
    websocketclose() {
      window.clearInterval(timer);
      timer = null;
    }
  },
  beforeDestroy() {
    window.clearInterval(timer);
    timer = null;
    this.websock.close();
  }
};
</script>
<style lang="less" scoped>
.chat-box {
  background: #f4f4f4;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  // .msg-box::-webkit-scrollbar {
  //   display: none;
  // }
  // .msg-box::-moz-scrollbar {
  //   display: none;
  // }
  // .msg-box::-ms-scrollbar {
  //   display: none;
  // }
  .msg-box {
    height: ceil(484px - 90px);
    width: 100%;
    padding-bottom: 30px;
    overflow-y: scroll;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
  .input-box {
    width: 100%;
    height: 50px;
    background: #e4e4e4;
    box-shadow: 0 0 5px #ccc;
    box-sizing: border-box;
    padding: 6px 20px;
    position: relative;
    .input-at {
      width: 250px;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(230, 230, 230, 1);
      border-radius: 19px;
      overflow: hidden;
    }
    input {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      font-size: 16px;
      color: #363636;
    }
    .btns {
      display: inline-block;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      background: url("~@/assets/images/curriculum/fs.png") no-repeat 0px 0px;
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -11px;
      cursor: pointer;
      &.active {
        background: url("~@/assets/images/curriculum/fs-1.png") no-repeat 0px
          0px;
      }
    }
  }
  .tab-header {
    width: 100%;
    height: 40px;
    background: #ffffff;
    overflow: hidden;
    box-sizing: border-box;
  }
  /deep/.el-tabs__item {
    width: 100%;
    text-align: center;
    font-size: 15px;
    &:hover {
      color: #00bd92;
    }
  }
  /deep/.el-tabs__item.is-active {
    color: #00bd92;
    font-weight: bold;
  }
  /deep/.el-tabs__active-bar {
    background: #00bd92;
    height: 3px;
  }
}
.header {
  padding: .5em;
  .finished {
    font-size: 14px;
    text-align: center;
    color: #bfbfbf;
  }
  .spinner {
    font-size: 10px;
    margin: 0px auto;
    text-indent: -9999em;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffffff;
    background: linear-gradient(to right, #ccc 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
  }
  .spinner:before {
    width: 50%;
    height: 50%;
    background: #ccc;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .spinner:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>