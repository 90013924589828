<template>
  <div class="container broadcast">
    <div class="container-fluid">
      <div class="title">{{ activityName }}</div>
      <div class="text">
        开课时间：{{
          courseStartTime
            | timeFormatter("{year}年{month}月{day}日 {hour}:{minute}:{second}")
        }}
      </div>
      <div class="clear videoBox">
        <div class="fl fl-bg">
          <div id="mse" v-if="show"></div>
        </div>
        <div class="fl chat">
          <chat
            :activityCourseId="activityCourseId"
            @broadcastIncident="broadcastIncident"
          ></chat>
        </div>
      </div>
      <div class="fl-bgs" v-if="!show">
        <div class="show">直播已结束，回放正在生成中，请耐心等待！</div>
      </div>
    </div>
  </div>
</template>
<script>
import Chat from './components/chat';
import 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
import { userWatchInfoLiveStreamingSendTime } from '@/api/api';
import { timeFormatter } from '@/utils/verify';
var timer, player;
export default {
  name: 'Broadcast',
  filters: { timeFormatter },
  components: {
    Chat
  },
  data() {
    return {
      activityName: '',
      courseStartTime: '',
      activityCourseId: '', // 聊天室id
      courseState: '',
      show: true
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      const {
        activityCourseId,
        url,
        courseStartTime,
        activityName,
        courseState
      } = this.$route.query;
      this.activityName = activityName;
      this.courseState = courseState;
      this.courseStartTime = courseStartTime;
      this.activityCourseId = activityCourseId;
      if (url != '') {
        player = new HlsJsPlayer({
          id: 'mse',
          url: url,
          width: 860,
          height: 484,
          preloadTime: 30,
          disableProgress: true
        });
        player.once('ended', () => {});
        player.on('pause', () => {
          window.clearInterval(timer);
          timer = null;
        });
        player.on('play', () => {
          window.clearInterval(timer);
          timer = null;
          timer = setInterval(() => {
            userWatchInfoLiveStreamingSendTime({
              activityCourseId: this.activityCourseId
            });
          }, 1000 * 30);
        });
      }
    },
    broadcastIncident(e) {
      if (e.code == 200) {
        this.show = false;
        window.clearInterval(timer);
        timer = null;
      }
    }
  },
  // 关闭页面断开直播
  beforeDestroy() {
    player.pause();
    player.destroy(true);
    this.show = false;
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.broadcast {
  width: 100%;
  box-sizing: border-box;
  height: 830px;
  background: @TEXT-BG-888888;
  padding-top: 40px;
  .title {
    .fontSize(@TEXT-SIZE-24);
    line-height: 31px;
    color: @TEXT-COLOR-FFFFFF;
    margin-bottom: 10px;
  }
  .text {
    .fontSize(@TEXT-SIZE-14);
    line-height: 24px;
    color: @TEXT-COLOR-FFFFFF;
    margin-bottom: 20px;
    span {
      margin-left: 30px;
    }
  }
  .videoBox {
    width: 100%;
    .chat {
      width: 320px;
      height: 484px;
      background: @TEXT-BG-FFFFFF;
    }
  }
  .fl-bg {
    background: #000;
    width: 860px;
    height: 484px;
  }
  .container-fluid {
    position: relative;
  }
  .fl-bgs {
    background: #000;
    width: 860px;
    height: 484px;
    position: absolute;
    top: 85px;
    left: 15px;
    z-index: 100000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    .show {
      color: #fff;
      font-size: 20px;
      border: 1px solid #fff;
      height: 50px;
      box-sizing: border-box;
      padding: 0 20px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
